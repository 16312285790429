export class BranchUtils {
  static desktopSendSMS(phone, fromPage, campaign, tags) {
    window.branch.sendSMS(
      phone,
      {
        channel: "Website",
        feature: "Text App Link",
        data: {
          campaign: campaign,
          tags: tags,
        },
      },
      { make_new_link: false },
      function (err) {
        console.log(err)
      }
    )
  }
}
