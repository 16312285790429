import { EMAIL_REGEX, PHONE_REGEX } from "../constants/CommonConstants"

export const isValidContentfulImage = (prop) => {
  return prop && prop.file && prop.file.url
}

export const isValidSectionField = (topSection, field) => {
  return topSection && topSection[field]
}

export const getProcessedArticles = (data) => {
  return data.nodes
    .filter((category) => category.topics)
    .flatMap((category) => {
      return category.topics
        .filter((topic) => topic.educationOrder && topic.publishOnWebsite)
        .map((topic) => {
          return {
            ...topic,
            categorySlug: category.slug,
          }
        })
        .flatMap((topic) => {
          return topic.educationOrder.map((article) => {
            return {
              ...article,
              topicSlug: topic.slug,
              categorySlug: category.slug,
            }
          })
        })
    })
}

export const isValidPhone = (phone) => {
  if (phone) {
    const phoneHasPlus = phone.startsWith("+1")
    const addPlus = phoneHasPlus ? phone : "+1" + phone

    if (addPlus.match(PHONE_REGEX)) {
      return addPlus
    } else {
      return null
    }
  }
}

export const isValidEmail = (email) => {
  return email && email.match(EMAIL_REGEX)
}
